import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useDeleteConnectionMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (value: Api.IGoogleBusinessMetadata) => void;
}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IGoogleBusinessMetadata>(
				'googleBusiness/connection',
				'DELETE',
				null
			);
		},
		onError,
		onSuccess,
	});
};
