import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useSchedulerConfigAddMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (response: Api.IMeetingConfigResponse) => void;
	onError?: (err: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ userId }: { userId: string }) => {
			return userSession.webServiceHelper.callAsync<Api.IMeetingConfigResponse>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						forUserId: userId,
					},
					urlPath: 'scheduler/config',
				}),
				'POST'
			);
		},
		onError,
		onSuccess,
	});
}
