import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import * as AdminModels from '../../../models/AdminModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useHwcEditTemplateMutation({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (value: AdminModels.ISystemEmailTemplate) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ id, template }: { id: string; template: Partial<Api.IHandwrittenCardTemplate> }) => {
			return userSession.webServiceHelper.callAsync<AdminModels.ISystemEmailTemplate>(
				`admin/template/industry/cardCategories/templates/${encodeURIComponent(id)}`,
				'PATCH',
				{ template }
			);
		},
		onError,
		onSuccess,
	});
}
