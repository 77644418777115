import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

export const CONVERSATION_MESSAGES_FILTER_QUERY_KEY = 'conversation/{id}/messages';

export const useConversationMessagesFilterQuery = ({
	enabled = true,
	conversationId,
	onError,
	pageSize = 25,
	refetchOnWindowFocus,
	type,
}: {
	enabled?: boolean;
	conversationId: string;
	onError?: (error: Api.IOperationResultNoValue) => void;
	pageSize?: number;
	refetchOnWindowFocus?: boolean;
	type?: string;
}) => {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.ITextMessage>>({
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		onError,
		queryFn: ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageToken: pageParam, pageSize },
					urlPath: `conversation/${conversationId}/messages`,
				}),
				'GET'
			);
		},
		queryKey: [CONVERSATION_MESSAGES_FILTER_QUERY_KEY, conversationId, pageSize, type],
		refetchOnWindowFocus,
	});
};

export function invalidateConversationMessages(conversationId: string, type?: string) {
	reactQueryClient.invalidateQueries([CONVERSATION_MESSAGES_FILTER_QUERY_KEY, conversationId, type]);
}

export function removeConversationMessages(conversationId: string, type?: string) {
	reactQueryClient.removeQueries([CONVERSATION_MESSAGES_FILTER_QUERY_KEY, conversationId, type]);
}
