import { useQuery } from '@tanstack/react-query';
import { stringify as toQueryString } from 'query-string';
import { IDictionary } from '../../extViewmodels';
import { useApiClientContext } from '../../surveys/context';
import { ISurvey } from '../../surveys/models/Api';

export const GET_SURVEY_BY_TOKEN_QUERY_KEY = 'getSurveyByTokenQuery';

export const useClientGetSurveyByToken = <T extends ISurvey>({
	accessToken,
	enabled = true,
	refetchOnWindowFocus = false,
	options = {},
}: {
	accessToken?: string;
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
	options?: IDictionary<string>;
}) => {
	const { apiClient } = useApiClientContext();
	return useQuery({
		enabled,
		queryFn: () => {
			return apiClient.get<T>(`survey/byToken?${toQueryString({ token: accessToken, ...options })}`);
		},
		queryKey: [GET_SURVEY_BY_TOKEN_QUERY_KEY, accessToken, options],
		refetchOnWindowFocus,
	});
};
