import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useDeactivateUserMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IUser) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ userId }: { userId: string }) => {
			return userSession.webServiceHelper.callAsync<Api.IUser>(
				`user/${userId}/deactivate`, // URL
				'POST', // Method
				null // Body (null if body should not be sent)
			);
		},
		onError,
		onSuccess,
	});
}
