import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'infiniteHwcPostsReport';

export function useInfiniteHwcPosts({
	pageSize = 25,
	selectedCardStatus,
	selectedUserId,
	impersonationContext,
}: {
	pageSize?: number;
	selectedCardStatus?: Api.CardStatus[];
	selectedUserId: string;
	impersonationContext?: Api.IImpersonationContext;
}) {
	const userSession = useUserSession();

	return useInfiniteQuery<Api.IPagedCollection<Api.IHandwrittenCard>>({
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: async ({ pageParam }) => {
			const startDate = impersonationContext?.account?.creationDate ?? userSession.account.creationDate;
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IHandwrittenCard>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { pageSize, pageToken: pageParam },
					urlPath: `reports/handwrittenCard`,
				}),
				'POST',
				{
					endDate: moment().add(180, 'days').endOf('day').toDate(),
					startDate: moment(startDate).startOf('day').toDate(),
					status: selectedCardStatus,
					userId: selectedUserId,
				}
			);
		},
		queryKey: [
			BASE_QUERY_KEY,
			{
				impersonationContext,
				pageSize,
				selectedCardStatus,
				selectedUserId,
			},
		],
	});
}

export function invalidateInfiniteHwcPosts() {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
}
