import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useTelephonyUploadAttachmentMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: () => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: async (file: File) => {
			const formData = new FormData();
			formData.append('file', file);
			return new Promise<Api.IFileAttachment>((resolve, reject) => {
				const onFinish = (opResult: Api.IOperationResult<Api.IFileAttachment>) => {
					if (opResult.success) {
						return resolve(opResult?.value);
					}
					reject(opResult);
				};
				userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IFileAttachment>(
					'telephony/configuration/brand/attachment',
					'POST',
					formData,
					onFinish,
					onFinish
				);
			});
		},
		onError,
		onSuccess,
	});
}
