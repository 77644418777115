import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useExportContactsMutation = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.ISystemJob) => void;
} = {}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ exportRequest }: { exportRequest: Api.IContactsExportRequest }) => {
			return userSession.webServiceHelper.callAsync<Api.ISystemJob>('contact/export', 'POST', exportRequest);
		},
		onError,
		onSuccess,
	});
};
