import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const INFINITE_USERS_QUERY_KEY = 'infiniteUsers';

export function useInfiniteUsers({
	pageSize = 25,
	impersonationContext,
	onError,
}: {
	pageSize?: number;
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.IUser>>({
		getNextPageParam: lastPage => lastPage.pageToken,
		onError,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IUser>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { pageSize, pageToken: pageParam },
					urlPath: `user/`,
				}),
				'GET',
				null
			);
		},
		queryKey: [INFINITE_USERS_QUERY_KEY, pageSize],
	});
}

export const invalidateInfiniteUsers = () => {
	reactQueryClient.invalidateQueries([INFINITE_USERS_QUERY_KEY]);
};
