import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const AUTOMATION_DETAILS_QUERY_KEY = 'automationDetails';

export const useAutomationDetails = ({
	automationId,
	pageSize = 25,
	startDate,
	endDate,
	userId,
}: {
	automationId: string;
	pageSize?: number;
	startDate?: Date;
	endDate?: Date;
	userId?: string;
}) => {
	const userSession = useUserSession();

	return useInfiniteQuery<Api.IPagedCollection<Api.IAutomationReportView>>({
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IAutomationReportView>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageSize, pageToken: pageParam },
					urlPath: `reports/automation/template/${automationId}`,
				}),
				'POST',
				{
					endDate: endDate.toISOString(),

					startDate: startDate.toISOString(),
					userId,
				}
			);
		},
		queryKey: [AUTOMATION_DETAILS_QUERY_KEY, { automationId, endDate, pageSize, startDate, userId }],
	});
};

export const invalidateAutomationDetails = () => {
	reactQueryClient.invalidateQueries([AUTOMATION_DETAILS_QUERY_KEY]);
};
