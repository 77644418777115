import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface IParams {
	template: Api.IHandwrittenCardTemplate;
}

export function useTemplateCardCreateMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: Api.IHandwrittenCardTemplate, variables: IParams) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ template }: IParams) =>
			userSession.webServiceHelper.callAsync<Api.IHandwrittenCardTemplate>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `template/card` }),
				`POST`,
				template
			),
		onError,
		onSuccess,
	});
}
