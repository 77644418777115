import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

interface IMutationVariables {
	industry: Api.Industry;
	categoryName: string;
	ids: string[];
}

export function useHwcCategoryTemplatesOrderMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IOperationResultNoValue, variables: IMutationVariables) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ industry, categoryName, ids }: IMutationVariables) => {
			return userSession.webServiceHelper.callAsync<Api.IOperationResultNoValue>(
				`admin/template/${encodeURIComponent(industry)}/${encodeURIComponent(categoryName)}/cardTemplates/order`,
				'PUT',
				ids
			);
		},
		onError,
		onSuccess,
	});
}
