import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useApiRequestTimeout } from '../../models/hooks/useApiRequestTimeout';

export function useEmailContentGeneration({
	impersonationContext,
	onError,
	onSuccess,
	timeout = 59000,
	userSession,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onError?(err: Api.IOperationResultNoValue): void;
	onSuccess?(
		emailContent: Api.IAIGeneratedContent<Api.IEmailMessageCompose>,
		request: Api.IEmailContentGenerationRequest
	): void;
	/** Timeout in milliseconds. Default = 59000 */
	timeout?: number;
	userSession: Api.UserSessionContext;
}) {
	const executeWithTimeout = useApiRequestTimeout();
	return useMutation({
		mutationFn: (request: Api.IEmailContentGenerationRequest) => {
			return executeWithTimeout(
				userSession.webServiceHelper.callAsync<Api.IAIGeneratedContent<Api.IEmailMessageCompose>>(
					Api.ImpersonationBroker.composeApiUrl({ impersonationContext, urlPath: 'email/generate' }),
					'POST',
					request
				),
				timeout
			);
		},
		onError,
		onSuccess,
	});
}
