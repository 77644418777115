import * as Api from '@ViewModels';
import { ImpersonationBroker } from '@ViewModels';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const INFINITE_COMPANY_TIMELINE_QUERY_KEY = 'infiniteCompanyTimeline';
const DEFAULT_PAGE_SIZE = 25;

interface IQueryParams {
	companyId: string;
	pageSize?: number;
	eventTypes: Api.TimelineEventTypes[];
}

export function useInfiniteCompanyTimeline({
	companyId,
	pageSize = DEFAULT_PAGE_SIZE,
	eventTypes,
	onError,
}: IQueryParams & {
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();

	return useInfiniteQuery<Api.IPagedCollection<Api.TimelineEventViewModel>>({
		getNextPageParam: lastPage => lastPage.pageToken,
		onError,
		queryFn: async ({ pageParam }) => {
			const result = await userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.ITimelineEvent>>(
				ImpersonationBroker.composeApiUrl({
					queryParams: {
						pageSize,
						pageToken: pageParam,
						typeOf: eventTypes,
					},
					urlPath: `timeline/Company/${companyId}`,
				}),
				'GET'
			);
			return {
				...result,

				values: result.values.map(event => createEventVm({ event, userSession })),
			};
		},
		queryKey: [INFINITE_COMPANY_TIMELINE_QUERY_KEY, companyId, eventTypes, pageSize],
	});
}

export function invalidateCompanyTimeline({ companyId, eventTypes, pageSize = DEFAULT_PAGE_SIZE }: IQueryParams) {
	reactQueryClient.invalidateQueries([INFINITE_COMPANY_TIMELINE_QUERY_KEY, companyId, eventTypes, pageSize]);
}

export function removeItemFromCompanyTimelineQueryCache({
	queryParams: { companyId, eventTypes, pageSize = DEFAULT_PAGE_SIZE },
	id,
}: {
	queryParams: IQueryParams;
	id: string;
}) {
	reactQueryClient.setQueryData(
		[INFINITE_COMPANY_TIMELINE_QUERY_KEY, companyId, eventTypes, pageSize],

		(data: InfiniteData<Api.IPagedCollection<Api.TimelineEventViewModel<Api.ITimelineEvent>>>) => {
			return {
				...data,
				pages: data.pages.map(page => {
					return {
						...page,
						values: page.values.filter(i => i.toJs().contentId !== id),
					};
				}),
			};
		}
	);
}

function createEventVm({
	event,
	userSession,
}: {
	event: Api.ITimelineEvent;
	userSession: Api.UserSessionContext;
}): Api.TimelineEventViewModel {
	switch (event?._type) {
		case 'ActionItemEvent': {
			return new Api.ActionItemEventViewModel(userSession, event as Api.IActionItemEvent);
		}
		case 'ConversationThreadEvent': {
			return new Api.ConversationThreadEventViewModel(userSession, event as Api.IConversationThreadEvent);
		}
		case 'SentEmailEvent': {
			return new Api.SentEmailEventViewModel(userSession, event as Api.ISentEmailEvent);
		}
		case 'HtmlNewsletterEvent': {
			return new Api.HtmlNewsletterEventViewModel(userSession, event as Api.IHtmlNewsletterEvent);
		}
		case 'NoteEvent': {
			return new Api.NoteEventViewModel(userSession, event as Api.INoteEvent);
		}
		case 'PhoneCallCompletedEvent': {
			return new Api.PhoneCallCompletedEventViewModel(userSession, event as Api.INoteEvent);
		}
		case 'UntrackedPhoneCallEvent': {
			return new Api.UntrackedPhoneCallEventViewModel(userSession, event as Api.INoteEvent);
		}
		case 'DealCreatedEvent': {
			return new Api.DealCreatedEventViewModel(userSession, event as Api.INoteEvent);
		}
		case 'DealUpdatedEvent': {
			return new Api.DealUpdatedEventViewModel(userSession, event as Api.INoteEvent);
		}
		case 'SkipLeadEvent': {
			return new Api.SkipLeadEventViewModel(userSession, event as Api.INoteEvent);
		}
		case 'PhoneCallEvent': {
			return new Api.PhoneCallEventViewModel(userSession, event);
		}
		case 'MeetingEvent': {
			return new Api.MeetingEventViewModel(userSession, event);
		}
		case 'FollowUpEvent': {
			return new Api.FollowUpEventViewModel(userSession, event);
		}
		case 'CancelledFollowUpEvent': {
			return new Api.CancelledFollowUpEventViewModel(userSession, event);
		}
		case 'RescheduledFollowUpEvent': {
			return new Api.RescheduledFollowUpEventViewModel(userSession, event);
		}
		case 'SurveyResponseEvent': {
			return new Api.SurveyResponseEventViewModel(userSession, event as Api.ISurveyResponseEvent);
		}
		case 'SatisfactionSurveyResponseEvent': {
			return new Api.SatisfactionSurveyResponseEventViewModel(
				userSession,
				event as Api.ISatisfactionSurveyResponseEvent
			);
		}
		case 'HandwrittenCardOrderEvent': {
			return new Api.HandwrittenCardOrderEventViewModel(userSession, event);
		}
		default: {
			break;
		}
	}

	return new Api.TimelineEventViewModel(userSession, event);
}
