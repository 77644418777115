import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useFeaturesMutation({
	impersonationContext,
	onError,
	onSuccess,
}: {
	impersonationContext: Api.IImpersonationContext;
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IHtmlNewsletterFeatures) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ features }: { features: Api.IHtmlNewsletterFeatures }) => {
			return userSession.webServiceHelper.callAsync<Api.IHtmlNewsletterFeatures>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `account/features/htmlNewsletter`,
				}),
				'PUT',
				features
			);
		},
		onError,
		onSuccess,
	});
}
