import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'googleBusinessReviews';

export function useInfiniteReviews({
	enabled,
	impersonationContext,
	pageSize = 10,
	sort = 'desc',
	refetchOnWindowFocus = true,
}: {
	enabled: boolean;
	impersonationContext?: Api.IImpersonationContext;
	pageSize?: number;
	sort?: 'asc' | 'desc';
	refetchOnWindowFocus: boolean;
}) {
	const userSession = useUserSession();

	return useInfiniteQuery<Api.IPagedCollection<Api.IGoogleBusinessReview>>({
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.IGoogleBusinessReview>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: {
						pageSize,
						pageToken: pageParam,
						sort,
						sortBy: 'updateTime',
					},
					urlPath: 'googleBusiness/reviews',
				}),
				'GET'
			);
		},
		queryKey: [
			BASE_QUERY_KEY,
			userSession.account?.id,
			{
				impersonationContext,
				pageSize,
				sort,
			},
		],
		refetchOnWindowFocus,
	});
}

export function invalidateInfiniteReviews() {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY]);
}
