import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useUserSession } from '../../models/hooks/appStateHooks';

const INFINITE_SOCIAL_MEDIA_POSTS_QUERY_KEY = 'infiniteSocialMediaPostReport';

export function useInfiniteSocialMediaPosts({
	pageSize = 25,
	selectedPostStatus,
	selectedUserId,
	impersonationContext,
	onError,
}: {
	pageSize?: number;
	selectedPostStatus: Api.PostStatus[];
	selectedUserId: string;
	impersonationContext?: Api.IImpersonationContext;
	onError?: (err: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.ISocialMediaPostReport>>({
		getNextPageParam: lastPage => lastPage.pageToken,
		onError,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.ISocialMediaPostReport>>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: { pageSize, pageToken: pageParam },
					urlPath: `reports/post`,
				}),
				'POST',
				{
					endDate: moment().add(180, 'days').endOf('day').toDate(),
					startDate: moment().subtract(180, 'days').startOf('day').toDate(),
					status: selectedPostStatus,
					userId: selectedUserId,
				}
			);
		},
		queryKey: [
			INFINITE_SOCIAL_MEDIA_POSTS_QUERY_KEY,
			pageSize,
			selectedPostStatus,
			selectedUserId,
			impersonationContext,
		],
	});
}
