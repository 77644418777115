import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

const BASE_QUERY_KEY = 'template/byType';

export interface ITemplatesByQueryParams {
	enabled?: boolean;
	forUserId?: string;
	userId?: string;
	type: Api.TemplateType;
	pageSize?: number;
	impersonationContext?: Api.IImpersonationContext;
}

export function useTemplatesByType({
	enabled,
	forUserId,
	userId,
	type,
	pageSize = 25,
	impersonationContext,
}: ITemplatesByQueryParams) {
	const userSession = useUserSession();
	return useInfiniteQuery({
		enabled,
		queryFn: ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.ITemplate>>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: 'template/byType',
					impersonationContext,
					queryParams: {
						forUserId,
						type,
						pageSize,
						userId,
						pageParam,
					},
				}),
				'GET'
			);
		},
		getNextPageParam: lastPage => lastPage.pageToken,
		queryKey: [BASE_QUERY_KEY, type, pageSize, userId, forUserId, impersonationContext],
	});
}

export function invalidateTemplatesByTypeQuery({
	forUserId,
	userId,
	type,
	pageSize = 25,
	impersonationContext,
}: {
	forUserId?: string;
	userId?: string;
	type: Api.TemplateType;
	pageSize?: number;
	impersonationContext?: Api.IImpersonationContext;
}) {
	return reactQueryClient.invalidateQueries({
		queryKey: [BASE_QUERY_KEY, type, pageSize, userId, forUserId, impersonationContext],
	});
}
