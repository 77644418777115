import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface IHandwrittenCardCostEstimation {
	totalRecipients: number;
	totalCost: number;
	totalRecipientsWithAddress?: number;
}

export function useHwcEstimateCostQuery({ filterRequest }: { filterRequest: Api.IBulkContactsRequest }) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () =>
			userSession.webServiceHelper.callAsync<IHandwrittenCardCostEstimation>(
				Api.ImpersonationBroker.composeApiUrl({ urlPath: `handwrittenCard/estimateCost` }),
				'POST',
				filterRequest
			),
		queryKey: ['handwrittenCard/estimateCost', filterRequest],
	});
}
