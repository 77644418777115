import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

interface IProps {
	personality: Api.AIPersonalityType;
	prompt: string;
	reviewId: string;
}

export const useGetGeneratedReviewResponse = ({
	onError,
	onSuccess,
}: {
	onError?: () => void;
	onSuccess: (value: Api.IGoogleBusinessReviewResponse) => void;
}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: (body: IProps) => {
			return userSession.webServiceHelper.callAsync<Api.IGoogleBusinessReviewResponse>(
				`googleBusiness/review/${encodeURIComponent(body.reviewId)}/generateReply`,
				'POST',
				body
			);
		},
		onError,
		onSuccess,
	});
};
