import * as Api from '@ViewModels';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export function useInfiniteNotesByMe({
	pageSize = 25,
	sort = 'desc',
	onError,
	enabled = true,
}: {
	pageSize?: number;
	sort?: 'asc' | 'desc';
	onError?: (err: Api.IOperationResultNoValue) => void;
	enabled?: boolean;
}) {
	const userSession = useUserSession();
	return useInfiniteQuery<Api.IPagedCollection<Api.INote>>({
		enabled,
		getNextPageParam: lastPage => lastPage.pageToken,
		onError,
		queryFn: async ({ pageParam }) => {
			return userSession.webServiceHelper.callAsync<Api.IPagedCollection<Api.INote>>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { pageSize, pageToken: pageParam, sort },
					urlPath: `note/byMe`,
				}),
				'GET'
			);
		},
		queryKey: ['note/byMe', pageSize, sort],
	});
}

export const invalidateNotesByMe = () => {
	reactQueryClient.invalidateQueries(['note/byMe']);
};
