import * as React from 'react';
import { ApiClient } from '../models/Api';

export interface IApiClientContext {
	apiClient: ApiClient;
}

export const ApiClientContext = React.createContext<IApiClientContext>(null);

export const useApiClientContext = () => {
	const context = React.useContext(ApiClientContext);
	if (!context) {
		throw new Error('This component required the use of "ApiClientContext"');
	}
	return context;
};

export const ApiClientContextProvider: React.FC = ({ children }) => {
	const context = React.useRef<IApiClientContext>({
		apiClient: new ApiClient({ baseUrl: process.env.API_URL }),
	}).current;
	return <ApiClientContext.Provider value={context}>{children}</ApiClientContext.Provider>;
};
