import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useUpdateUserCardSettingsMutation({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.IUser) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ forUser, settings }: { forUser: string; settings: Api.IUserPreferences }) => {
			return userSession.webServiceHelper.callAsync<Api.IUser>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { forUserId: forUser },
					urlPath: `user/userPreferences/handwrittenCardPreferences`,
				}), // URL
				'PUT', // Method
				settings.handwrittenCardPreferences // Body (null if body should not be sent)
			);
		},
		onError,
		onSuccess,
	});
}
