import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useEmailSendNowMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (campaign: Api.ICampaign) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ id }: { id: string }) => {
			return userSession.webServiceHelper.callAsync(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `email/${encodeURIComponent(id)}/sendNow`,
				}),
				'PUT'
			);
		},
		onError,
		onSuccess,
	});
}
