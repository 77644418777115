import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export const useConnectGoogleBusiness = ({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (value: Api.IGoogleBusinessConnectResponse) => void;
}) => {
	const userSession = useUserSession();

	return useMutation({
		mutationFn: () => {
			return userSession.webServiceHelper.callAsync<Api.IGoogleBusinessConnectResponse>(
				'oauthlink/googlebusiness/authUrl',
				'POST',
				null
			);
		},
		onError,
		onSuccess,
	});
};
