import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const useRestoreArchivedOpportunity = ({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (data: Api.ISystemJob) => void;
} = {}) => {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ itemId, stageId }: { itemId: string; stageId: string }) => {
			return userSession.webServiceHelper.callAsync<Api.ISystemJob>(
				`board/item/${itemId}/archive/restore/${stageId}`,
				'POST',
				null
			);
		},
		onError,
		onSuccess,
	});
};
