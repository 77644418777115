import { useQuery } from '@tanstack/react-query';
import * as Api from '@ViewModels';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

interface FilteredNewsletterQueryArgs {
	enabled?: boolean;
	excludeExpired?: boolean;
	impersonationContext?: Api.IImpersonationContext;
	industry?: Api.Industry;
	onError?: (err: Api.IOperationResultNoValue) => void;
	search?: string;
	templateType?: Api.TemplateType;
}

const BASE_QUERY_KEY = '{industryName}/htmlNewsletter/filter';

export const useGetNewsletterTemplatesByKeyword = ({
	enabled,
	excludeExpired = true,
	impersonationContext,
	industry,
	onError,
	search,
	templateType = Api.TemplateType.HtmlNewsletter,
}: FilteredNewsletterQueryArgs) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: async () => {
			return userSession.webServiceHelper.callAsync<Api.ITemplate[]>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: { search, excludeExpired, industryName: industry, templateType },
					urlPath: `template/${encodeURIComponent(industry)}/htmlNewsletter/filter`,
					impersonationContext,
				}),
				'GET',
				null
			);
		},
		queryKey: [BASE_QUERY_KEY],
	});
};

export const invalidateGetNewsletterTemplatesByKeyword = ({
	impersonationContext,
	industry,
	templateType,
}: FilteredNewsletterQueryArgs) => {
	reactQueryClient.invalidateQueries([BASE_QUERY_KEY, impersonationContext, industry, templateType]);
};
