import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../web/ReactQueryProvider';

export const TEMPLATE_KEYWORDS_QUERY_KEY = 'templateKeywords';

export const useTemplateKeywords = ({
	industry,
	enabled = true,
	onError,
}: {
	industry: Api.Industry;
	enabled?: boolean;
	onError?: (err: Api.IOperationResultNoValue) => void;
}) => {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: async () => {
			const value = await userSession.webServiceHelper.callAsync<string[]>(
				`template/${encodeURIComponent(industry)}/keywords`,
				'GET'
			);
			return value;
		},
		queryKey: [TEMPLATE_KEYWORDS_QUERY_KEY, industry],
	});
};

export const invalidateTemplateKeywords = ({ industry }: { industry: Api.Industry }) => {
	reactQueryClient.invalidateQueries([TEMPLATE_KEYWORDS_QUERY_KEY, industry]);
};
