import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import * as AdminModels from '../../../models/AdminModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';

interface IVariables {
	templateId: string;
	statuses: Api.ITemplateStatus[];
}

export function useHwcTemplateAddStatusesMutation({
	onError,
	onSuccess,
}: {
	onError?: (err: Api.IOperationResultNoValue) => void;
	onSuccess?: (value: AdminModels.ISystemEmailTemplateWithStatuses, variables?: IVariables) => void;
} = {}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({ templateId, statuses }: IVariables) => {
			return userSession.webServiceHelper.callAsync<AdminModels.ISystemEmailTemplateWithStatuses>(
				`admin/template/industry/cardCategories/${encodeURIComponent(templateId)}/statuses`,
				'POST',
				statuses
			);
		},
		onError,
		onSuccess,
	});
}
