import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useAutomationContactPreviewMutation({
	impersonationContext,
	onSuccess,
	onError,
}: {
	impersonationContext?: Api.IImpersonationContext;
	onSuccess?: (data: Api.IPreviewCreateAutomationResult) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();

	return useMutation({
		onSuccess,
		onError,
		mutationFn: ({
			id,
			createAutomationRequest,
		}: {
			id: string;
			createAutomationRequest: Api.ICreateAutomationRequest;
		}) => {
			return userSession.webServiceHelper.callAsync<Api.IPreviewCreateAutomationResult>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					urlPath: `Automation/${id}/Contact/preview`,
				}),
				'POST',
				createAutomationRequest
			);
		},
	});
}
