import { IFormField } from '../../extViewmodels';

export type SurveyTypes = 'Survey' | 'SatisfactionSurvey' | 'EventRegistrationSurvey';
export type ApiModelType = SurveyTypes;

export interface IBaseApiTypeModel {
	_type?: ApiModelType;
}

export interface IBaseApiModel extends IBaseApiTypeModel {
	id?: string;
	/** This is truly an optional value. This is not always guaranteed to be there. */
	lastModifiedDate?: string;
}

export interface IOperationResultNoValue {
	requestId?: string;
	success?: boolean;
	systemCode?: number;
	systemMessage?: string;
}

export interface IOperationResult<T> extends IOperationResultNoValue {
	value?: T;
}

export interface IBulkOperationResult<T> extends IOperationResultNoValue {
	succeeded?: T[];
	failed?: IOperationResult<T>[];
}

export const asApiError = (e: any) => {
	let error: IOperationResultNoValue = { success: false };
	if (e) {
		if (Object.prototype.hasOwnProperty.call(e, 'systemMessage')) {
			if (Object.prototype.hasOwnProperty.call(e, 'systemCode')) {
				error = e as IOperationResultNoValue;
				error.success = false;
			} else {
				error.systemMessage = e.systemMessage;
			}
		} else if (e instanceof Error) {
			error.systemMessage = e.message;
		} else if (typeof e === 'string') {
			error.systemMessage = e;
		} else if (Object.prototype.hasOwnProperty.call(e, 'toString') && typeof e.toString === 'function') {
			error.systemMessage = e.toString();
		} else {
			error.systemMessage = 'Unexpected error.';
		}

		if (!error.systemCode && (error.systemMessage || '').toLocaleLowerCase() === 'failed to fetch') {
			error.systemCode = -1;
			error.systemMessage = 'Error connecting to Levitate.';
		}
	} else {
		error.systemMessage = 'Unexpected error.';
	}

	return error;
};

export enum HTTPMethod {
	DELETE = 'DELETE',
	GET = 'GET',
	PATCH = 'PATCH',
	POST = 'POST',
	PUT = 'PUT',
}

export interface IApiClientConfig {
	accessToken?: string;
	baseUrl: string;
}

export class ApiClient {
	protected mConfig: IApiClientConfig;

	constructor(config: IApiClientConfig) {
		this.mConfig = config;
	}

	public get baseUrl() {
		return this.mConfig.baseUrl;
	}

	public get = <T>(path: string) => {
		return this.executeRequest<T>(this.buildFetchRequest(path, HTTPMethod.GET));
	};

	public put = <T>(path: string, body?: T) => {
		return this.executeRequest<T>(this.buildFetchRequest(path, HTTPMethod.PUT, body));
	};

	public patch = <T>(path: string, body?: any) => {
		return this.executeRequest<T>(this.buildFetchRequest(path, HTTPMethod.PATCH, body));
	};

	public delete = <T>(path: string) => {
		return this.executeRequest<T>(this.buildFetchRequest(path, HTTPMethod.DELETE));
	};

	public post = <T>(path: string, body?: any) => {
		return this.executeRequest<T>(this.buildFetchRequest(path, HTTPMethod.POST, body));
	};

	protected buildFetchRequest = (path: string, method: HTTPMethod, obj: any = null) => {
		// Check for a special method called FILEPOST, which we will transform to POST but will not convert body to JSON
		const isFormDataBody = obj instanceof FormData;

		// Define headers
		const headers = new Headers();
		if (isFormDataBody) {
			// DO NOT INCLUDE A HEADER FOR FILE POSTS
		} else {
			headers.append('Content-Type', 'application/json; charset=utf-8');
		}
		headers.append('Accept', 'application/json');
		headers.append('Pragma', 'no-cache');
		headers.append('Cache-Control', 'no-cache');

		if (this.mConfig.accessToken) {
			headers.append('Authorization', 'bearer ' + this.mConfig.accessToken);
		}
		const url = `${this.mConfig.baseUrl}/${path}`;
		const config: RequestInit = {
			headers,
			method,
			mode: 'cors',
			redirect: 'follow',
		};

		if (method.toLowerCase() !== 'get' && !!obj) {
			config.body = isFormDataBody ? obj : JSON.stringify(obj);
		}

		const request = new Request(url, config);
		return request;
	};

	protected executeRequest = async <T>(request: Request): Promise<T> => {
		const response = await fetch(request);
		if (response.status >= 400) {
			throw asApiError(await response.json());
		}
		const apiResponse: IOperationResult<T> = await response.json();

		return apiResponse.value;
	};
}

export interface IOperationResultNoValue {
	requestId?: string;
	success?: boolean;
	systemCode?: number;
	systemMessage?: string;
}

export interface IOperationResult<T> extends IOperationResultNoValue {
	value?: T;
}

export interface IBulkOperationResult<T> extends IOperationResultNoValue {
	succeeded?: T[];
	failed?: IOperationResult<T>[];
}

export interface ISurveyReviewRequestSettings {
	isEnabled: boolean;
	ratings?: number[];
	reviewRequest?: string;
	reviewType?: string;
	reviewUrl?: string;
}

export interface ISurvey extends IBaseApiModel {
	_type: SurveyTypes;
	archivedDate?: string; // Date
	companyDisplayName?: string;
	expirationDate?: string; // Date
	intro?: string;
	name?: string;
	responsesAllowed?: boolean;
	startDate?: string; // Date
}

export interface CustomForm<T = any> {
	fields: IFormField<T>[];
	name: string;
}

export interface CustomFormMetaData {
	fieldName?: string;
	fieldId?: string;
	ratings: number[];
}
export interface ISatisfactionSurvey extends ISurvey {
	_type: 'SatisfactionSurvey';
	reviewRequestSettings?: ISurveyReviewRequestSettings;
	customForm?: CustomForm<string>;
	customFormIsDisabled?: boolean;
	customFormMetaData?: CustomFormMetaData[];
}

export interface IFileAttachment {
	embedded?: boolean;
	fileName: string;
	fileSize: number;
	id: string;
	mimeType?: string;
	url?: string;
}

export interface IEventInformation {
	location: string;
	startTime: string;
	endTime?: string;
	details: string;
	image?: IFileAttachment;
	timeZone?: string;
}

export interface IAttendeeOptions {
	enabled?: boolean;
	requireNameAndEmail?: boolean;
	requirePhoneNumber?: boolean;
	guestLimit?: number;
	maximumCapacity?: number;
	registrationDeadline: Date | string;
}

export interface IEventSurveyStatsSubtotals {
	attending?: number;
	notAttending?: number;
	noResponse?: number;
}

export interface IEventResponseStats extends IEventSurveyStatsSubtotals {
	category: string;
}

export interface IEventStats {
	responseStats?: IEventResponseStats[];
}

export interface IEventRegistrationSurvey extends ISurvey {
	_type: 'EventRegistrationSurvey';
	attendeeOptions?: IAttendeeOptions;
	eventInformation?: IEventInformation;
	name?: string;
	stats?: IEventStats;
	customForm?: CustomForm<string>;
	customFormIsDisabled?: boolean;
}

export interface ISurveyResponse extends IBaseApiModel {
	contact?: IBaseApiModel;
	surveyId?: string;
	customProperties?: Record<string, string>;
	token?: string;
}

export interface ISatisfactionSurveyResponse extends ISurveyResponse {
	externalReviewClickDate?: string; // date
	feedback?: string;
	rating: number;
}

export interface IEventRecipient {
	email?: string;
	name?: string;
	firstName?: string;
	lastName?: string;
	phone?: string;
}

export interface IEventSurveyResponse extends ISurveyResponse {
	anonymousGuestCount?: number;
	attendee?: IEventRecipient;
	guests?: IEventRecipient[];
	isAttending?: boolean;
	token?: string;
}
