import * as Api from '@ViewModels';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import * as AdminModels from '../../../models/AdminModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { reactQueryClient } from '../../../web/ReactQueryProvider';

const scope = 'hwcIndustryCategoryTemplates';

const keys = {
	all: [{ scope }] as const,
	industryCategoryTemplates: (industry: Api.Industry, categoryName: string) =>
		[{ category: categoryName, industry, scope }] as const,
	industryTemplates: (industry: Api.Industry) => [{ industry, scope }] as const,
};

const fetchTemplates =
	(userSession: Api.IUserSessionContext) =>
	({ queryKey: [{ category, industry }] }: QueryFunctionContext<ReturnType<typeof keys.industryCategoryTemplates>>) => {
		return userSession.webServiceHelper.callAsync<AdminModels.ISystemEmailTemplate[]>(
			`admin/template/${encodeURIComponent(industry)}/cardCategories/${encodeURIComponent(category)}/templates`,
			'GET'
		);
	};

export function useHwcIndustryCategoryTemplates({
	industry,
	categoryName,
}: {
	industry: Api.Industry;
	categoryName: string;
}) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: fetchTemplates(userSession),
		queryKey: keys.industryCategoryTemplates(industry, categoryName),
	});
}

export function invalidateHwcIndustryCategoryTemplates({
	industry,
	categoryName,
}: {
	industry: Api.Industry;
	categoryName: string;
}) {
	return reactQueryClient.invalidateQueries(keys.industryCategoryTemplates(industry, categoryName));
}

export function invalidateAllHwcIndustryCategoryTemplates() {
	return reactQueryClient.invalidateQueries({
		predicate: query => (query.queryKey as typeof keys.all)[0]?.scope === scope,
	});
}

export function prefetchHwcIndustryCategoryTemplates({
	userSession,
	industry,
	categoryName,
	staleTime,
}: {
	userSession: Api.IUserSessionContext;
	industry: Api.Industry;
	categoryName: string;
	staleTime?: number;
}) {
	reactQueryClient.prefetchQuery({
		queryFn: fetchTemplates(userSession),
		queryKey: keys.industryCategoryTemplates(industry, categoryName),
		staleTime,
	});
}

export function getHwcIndustryCategoryTemplatesCache({
	industry,
	categoryName,
}: {
	industry: Api.Industry;
	categoryName: string;
}) {
	return reactQueryClient.getQueryData<AdminModels.ISystemEmailTemplate[]>(
		keys.industryCategoryTemplates(industry, categoryName)
	);
}

export function setHwcIndustryCategoryTemplatesCache({
	data,
	industry,
	categoryName,
}: {
	data: AdminModels.ISystemEmailTemplate[];
	industry: Api.Industry;
	categoryName: string;
}) {
	return reactQueryClient.setQueryData(keys.industryCategoryTemplates(industry, categoryName), () => data);
}
